<template>
  <div class="edito">
    <img width="100%" src="../assets/images/bdform.png" />
    <div class="mentions" v-html="mentions" />
  </div>
</template>
<script>
export default {
  name: 'Mentions',
  data() {
    return {
      mentions : window.mentions
    };
  }  
}
</script>